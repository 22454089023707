video.detail{
    height:250px;
    width:100%;
}
video.index{
    height:220px;
    width:100%;
}
@media (min-width: 1040px) {
video.detail{
    height:600px;
}
}
